<template>
  <div>
    <h1>Generate match link</h1>
    <form>
      <div class="container">
        <select v-model="selectedPartner">
          <option disabled selected value="">Select a Partner</option>
          <option v-for="partner in partners" :key="partner" :value="partner">
            {{partner}}
          </option>
        </select>
      </div>
      <div class="container">
        <select v-model="selectedHomeClub">
          <option :disabled="!selectedHomeClub" :selected="!selectedHomeClub" value="">{{!selectedHomeClub ? 'Select home club' : 'none'}}</option>
          <option v-for="club in clubs" :key="club" :disabled="club === selectedAwayClub" :value="club">
            {{club}}
          </option>
        </select>
        vs.
        <select v-model="selectedAwayClub">
          <option :disabled="!selectedAwayClub" :selected="!selectedAwayClub" value="">{{!selectedAwayClub ? 'Select away club' : 'none'}}</option>
          <option v-for="club in clubs" :key="club" :disabled="club === selectedHomeClub" :value="club">
            {{club}}
          </option>
        </select>
      </div>
    </form>
    <a :href="link" target="_blank">{{link}}</a>
  </div>
</template>

<script>
export default {
  created() {
    const partners = ['bild.de']
    const clubs = ['FCB', 'SCF', 'WOB']
    this.partners = partners.concat(clubs)
    this.clubs = clubs
  },
  data() {
    return {
      partners: [],
      clubs: [],
      selectedPartner: '',
      selectedHomeClub: '',
      selectedAwayClub: '',
    }
  },
  computed: {
    link() {
      if (this.selectedPartner.length === 0) return null
      const matchParam = this.selectedHomeClub && this.selectedAwayClub
        ? `&match=${this.selectedHomeClub}~${this.selectedAwayClub}`
        : ''
      return `${process.env.VUE_APP_WEBVIEW_URL}?partner=${this.selectedPartner}${matchParam}`
    }
  }
}
</script>

<style scoped>
.container {
  padding: 10px;
}
</style>
