<template>
  <div class="container">
    <div>
      <h1>All Recordings</h1>
      <div v-if="isLoading">loading...</div>
      <ul>
        <li v-for="recording in notRunningRecordings" :key="recording.fixture.id">
          <small>{{date(recording)}}</small>
          &nbsp;
          <button @click="currentRecording = recording" :disabled="recording.status.__type !== 'Completed'">{{recording.fixture.homeClub.name}} vs {{recording.fixture.awayClub.name}}</button>
          <small v-if="recording.status.__type !== 'Completed'">🔴</small>
        </li>
      </ul>
    </div>

    <div>
      <h1>Running Replays</h1>
      <div v-if="isLoading">loading...</div>
      <ul>
        <li v-for="recording in runningRecordings" :key="recording.fixture.id">
          <small>{{date(recording)}}</small>
          &nbsp;
          <button @click="currentRecording = recording">{{recording.fixture.homeClub.name}} vs {{recording.fixture.awayClub.name}}</button>
          &nbsp;
          <a :href="webviewUrl(recording)" target="_blank">Watch…</a>
        </li>
      </ul>
    </div>


    <div v-if="currentRecording" class="current-replay-modal">
      <button class="close-button" @click="currentRecording = null">x</button>
      <h1 class="replay-title">
        {{ currentRecording.fixture.homeClub.name }} vs {{ currentRecording.fixture.awayClub.name }}
      </h1>
      <p>{{ date(currentRecording) }}</p>
      <form @submit.prevent="startReplay(currentRecording)">
        <label>
          Speed
          <input type="number" placeholder="speed" v-model="speed">
        </label>
        <label>
          Delay in seconds
          <input type="number" placeholder="delay in seconds" v-model="delaySeconds">
        </label>
        <button class="button-start" v-if="!currentRecording.replay">Start</button>
      </form>
      <button
        v-if="currentRecording.replay"
        class="button-stop"
        @click="stopReplay(currentRecording)"
      >
        stop
      </button>

      <!-- <p><a :href="this.matchLink" target="_blank">{{this.matchLink}}</a></p> -->
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import commands from '../constants/commands'

export default {
  name: 'ReplaysList',

  async created() {
    this.listRecordings();
  },

  data() {
    return {
      recordings: [],
      currentRecording: null,
      delaySeconds: 0,
      speed: 1,
      isLoading: true,
    }
  },

  computed: {
    notRunningRecordings() {
      return this.recordings.filter(r => !r.replay);
    },
    runningRecordings() {
      return this.recordings.filter(r => r.replay);
    },
    // matchLink() {
    //   return `${process.env.VUE_APP_WEBVIEW_URL}?partner={partner}&match=${this.currentReplay.config.homeTeamCode}~${this.currentReplay.config.awayTeamCode}`
    // }
  },

  watch: {
    recordings(newValue) {
      if (!this.currentRecording) return
      this.currentRecording = newValue.find(r => r.fixture.id === this.currentRecording.fixture.id)
    }
  },

  methods: {
    webviewUrl(recording) {
      return `${process.env.VUE_APP_WEBVIEW_URL}?partner=example&match=${recording.fixture.homeClub.code}~${recording.fixture.awayClub.code}`
    },
    listRecordings() {
      this.isLoading = true
      axios.post('commands', { __type: commands.LIST_RECORDINGS })
        .then((response) => {
          this.recordings = response.data.result.sort((a, b) => b.fixture.startTimestamp - a.fixture.startTimestamp);
          this.isLoading = false;
          this.setMatchesState(this.recordings)
        });
    },
    startReplay(recording) {
      axios.post('commands', {
        __type: commands.START,
        matchId: recording.fixture.id,
        delay: `PT${this.delaySeconds}S`,
        speed: this.speed
      })
      .then(({ data }) => {
        this.changeCurrentRecording({ replay: data.result })
      });
    },
    stopReplay(recording) {
      axios.post('commands', {
        __type: commands.STOP,
        matchId: recording.fixture.id
      })
      .then(() => {
        this.changeCurrentRecording({ replay: null })
      });
    },
    changeCurrentRecording(newState) {
      this.recordings = this.recordings.map((recording) => {
        if (recording.fixture.id === this.currentRecording.fixture.id)
          return { ...recording, ...newState}
        else
          return recording
      })
    },
    date(recording) {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      }

      return new Date(recording.fixture.startTimestamp).toLocaleString('de-DE', options)
    },
    setMatchesState(recordings) {
      this.recordings = recordings
    }
  },
}
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
}
.container > div {
  margin: 0 20px;
  min-width: 500px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  text-align: left;
  margin: 5px 10px;
}
p {
  color: white;
}
button {
  padding: 10px;
  cursor: pointer;
}
small {
  margin-left: 5px;
}
.current-replay-modal {
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.9);
}
.close-button {
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 50%;
  background-color: white;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.replay-title {
  color: white;
}
h2 {
  color: white;
}
.button-start {
  background-color: green;
  color: white;
}
.button-start:disabled {
  background-color: rgb(157, 224, 157);
  cursor: not-allowed;
}
.button-stop {
  background-color: red;
  color: white;
}
.button-stop:disabled {
  background-color: rgb(240, 151, 151);
  cursor: not-allowed;
}
form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px;
  color: white;
}

input {
  padding: 5px 10px;
}

.start-params-modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000000f0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 500px;
    height: 500px;
}
</style>
